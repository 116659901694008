<template>
  <!-- Tab: Country settings -->
  <b-tab
    v-if="canSee()"
    @click="fetchData()"
  >
    <template #title>
      <feather-icon
        icon="PackageIcon"
        size="16"
        class="mr-0 mr-sm-50"
      />
      <span class="d-none d-sm-inline">{{ countryCode.toUpperCase() }} customs</span>
    </template>
    <div v-if="dataLoaded">
      <div v-if="countryCode === 'sk'">
        <validation-observer ref="customsValidation">
          <b-row>
            <b-col cols="6">
              <b-form-group
                label="SK-IAM username"
                label-for="username"
              >
                <validation-provider
                  #default="{ errors }"
                  name="SK-IAM username"
                  rules="required"
                >
                  <b-form-input
                    id="username"
                    v-model="formData.sk.username"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="SK-IAM password"
                label-for="password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="SK-IAM password"
                >
                  <b-form-input
                    id="password"
                    v-model="formData.sk.password"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="Customs senderId (URI štruktúra pre doručovanie)"
                label-for="senderId"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Customs senderId (URI štruktúra pre doručovanie)"
                  rules="required"
                >
                  <b-form-input
                    id="senderId"
                    v-model="formData.sk.senderId"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="Customs subjectId (Identifikátor EKR)"
                label-for="subjectId"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Customs subjectId (Identifikátor EKR)"
                  rules="required"
                >
                  <b-form-input
                    id="subjectId"
                    v-model="formData.sk.subjectId"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </div>
      <div v-if="countryCode === 'lt'">

        <h5><b>Here is the certificate and its private key that will be used to sign all declarations sent to Lithuanian customs on behalf of this company</b>
        </h5>
        <p>
          👉 Instructions on how to create and extract the certificate and private key can be found in
          <a
            href="https://bap.lrmuitine.lt/bap-app/"
            target="_blank"
          >BAP (Lithuanian Customs Portal)</a>.
        </p>
        <p>
          You may upload one of the following:
        </p>
        <ul>
          <li>A certificate with the exported private key in a <b>single file (.pfx format)</b>.</li>
          <li>A certificate and its private key as <b>two separate files</b> (select or drag and drop them together).</li>
        </ul>
        <p>If any of the files is encrypted, please provide the password.</p>

        <h6>Important:</h6>
        <div>
          The private individual to whom the certificate is issued must have the following permissions granted by the
          company in <b>BAP:</b>
        </div>
        <ul>
          <li><b>Business Profile:</b> "iMDAS_LVC"</li>
          <li><b>Rights Granted:</b> "Data exchange of electronic low-value consignment declarations with Lithuanian Customs
            and monitoring of their customs clearance"
          </li>
        </ul>
        <div v-if="hasSoonExpiringCertificate && hasValidCertificate">
          <b-alert
            show
            variant="warning"
            class="p-1"
          >
            Certificate will expire soon {{ formData.lt.certValidTo }} utc
          </b-alert>
        </div>
        <div v-else-if="hasValidCertificate">
          <b-alert
            show
            variant="success"
            class="p-1"
          >
            Certificate is valid until {{ formData.lt.certValidTo }} utc
          </b-alert>
        </div>
        <div v-else>
          <b-alert
            show
            variant="danger"
            class="p-1"
          >
            No valid certificate.
            <span v-if="formData.lt.certValidTo">Certificate expired on {{ formData.lt.certValidTo }} utc</span>
          </b-alert>
        </div>
        <validation-observer ref="customsValidation">
          <b-row>
            <b-col cols="6">
              <b-form-group
                label="Certificate and Private key Files"
                label-for="certFiles"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Certificate Files"
                  rules="required"
                >
                  <b-form-file
                    id="certFiles"
                    v-model="formData.lt.certFiles"
                    accept=".pem,.crt,.pfx,.txt,.cer"
                    placeholder="Upload .pfx .pem .crt .cer .txt files"
                    drop-placeholder="Drop files here..."
                    multiple
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <div>
                    <small
                      v-if="formData.lt.certUpdatedAt"
                      class="text-muted"
                    >Last updated time {{ formData.lt.certUpdatedAt }} utc</small>
                  </div>
                </validation-provider>

              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="Password"
                label-for="password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  :rules="''"
                >
                  <b-form-input
                    id="password"
                    v-model="formData.lt.password"
                    v-b-tooltip.hover.top="'Passphrase to unlock .pfx and encrypted private key files'"
                    :state="errors.length > 0 ? false : null"
                    type="password"
                    placeholder="Enter password here"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </div>
      <div v-if="countryCode === 'lv'">
        <validation-observer ref="customsValidation">
          <b-row>
            <b-col cols="6">
              <b-form-group
                label="Technical Username"
                label-for="tech-username"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Technical Username"
                  rules="required"
                >
                  <b-form-input
                    id="tech-username"
                    v-model="formData.lv.techUser"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="Technical Password"
                label-for="tech-password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Technical Password"
                  rules="required"
                >
                  <b-form-input
                    id="tech-password"
                    v-model="formData.lv.techPassword"
                    :type="'password'"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="System Code"
                label-for="system-code"
              >
                <validation-provider
                  #default="{ errors }"
                  name="System Code"
                  rules="required"
                >
                  <b-form-input
                    id="system-code"
                    v-model="formData.lv.systemCode"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="TIN"
                label-for="tin"
              >
                <validation-provider
                  #default="{ errors }"
                  name="TIN"
                  rules="required"
                >
                  <b-form-input
                    id="tin"
                    v-model="formData.lv.tin"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
      </div>
    </div>
    <div v-else>
      <b-spinner
        v-if="!dataLoaded"
        variant="secondary"
        label="Loading"
      />
    </div>

    <!-- Submit button -->
    <b-row v-if="dataLoaded">
      <b-col>
        <b-button
          id="customs-settings-submit"
          type="button"
          variant="primary"
          @click="submitForm"
        >
          Submit
        </b-button>
      </b-col>
    </b-row>
  </b-tab>
</template>
<script>
import {
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BTab,
  BButton, BSpinner, BFormFile, VBTooltip, BAlert,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full'
import handleError from '@/views/components/errorHandler'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'

export default {
  components: {
    BAlert,
    BFormFile,
    BSpinner,
    BButton,
    BTab,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: ['country', 'companyId'],
  data() {
    return {
      supportedCountries: [
        'lv',
        'lt',
        'sk',
      ],
      countryCode: '',
      dataLoaded: false,
      formData: {
        lv: {
          edsUser: '',
          edsPassword: '',
          systemCode: '',
          techUser: '',
          techPassword: '',
          tin: '',
        },
        lt: {
          certFiles: [],
          password: '',
          certValidTo: '',
          certId: '',
          certUpdatedAt: '',
        },
        sk: {
          username: '',
          password: '',
          subjectId: '',
          senderId: '',
        },
      },
    }
  },
  computed: {
    hasValidCertificate() {
      return moment(this.formData.lt.certValidTo) > moment()
    },
    hasSoonExpiringCertificate() {
      const daysUntilExpiry = moment(this.formData.lt.certValidTo).diff(moment(), 'days')
      return daysUntilExpiry < 30
    },
  },
  watch: {
    country(newValue) {
      this.countryCode = newValue.toLowerCase()
      if (this.supportedCountries.includes(this.countryCode)) {
        this.dataLoaded = false
        this.fetchData()
      }
    },
  },
  created() {
    this.countryCode = this.country.toLowerCase()
  },
  methods: {
    canSee() {
      if (
        this.countryCode === 'sk'
        && !this.$permissions().hasRole('Feeport Admin')
        && !this.$permissions().hasRole('Admin')
      ) {
        return false
      }
      return this.supportedCountries.includes(this.countryCode)
    },
    submitForm() {
      this.$refs.customsValidation.validate().then(success => {
        if (!success) {
          return
        }

        let formData = this.formData[this.countryCode]
        let config = {}

        if (this.countryCode === 'lt') {
          formData = new FormData()
          this.formData.lt.certFiles.forEach((file, i) => {
            formData.append(`files[${i}]`, file)
          })
          formData.append('password', this.formData.lt.password)
          config = {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        }

        this.$http.post(`/v1/companies/${this.companyId}/customs/${this.countryCode}`, formData, config)
          .then(response => {
            this.dataLoaded = false
            this.fetchData()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.message,
                icon: 'EditIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            handleError(error, this.$toast)
          })
          .finally(() => {
            this.formData.sk.password = ''
          })
      })
    },
    fetchData() {
      if (!this.dataLoaded) {
        this.$http.get(`/v1/companies/${this.companyId}/customs/${this.countryCode}`)
          .then(response => {
            switch (this.countryCode) {
              case 'lv':
                this.$data.formData.lv = response.data
                break
              case 'lt':
                if (response.data.certificate) {
                  this.$data.formData.lt.certValidTo = response.data.certificate.validTo
                  this.$data.formData.lt.certUpdatedAt = response.data.certificate.updatedAt
                  this.$data.formData.lt.certId = response.data.certificate.id
                }
                break
              case 'sk':
                this.$data.formData.sk = response.data
                break
              default:
                break
            }
          })
          .catch(error => {
            handleError(error, this.$toast)
          })
          .finally(() => {
            this.dataLoaded = true
          })
      }
    },
  },
}
</script>
