<template>
  <b-row>
    <b-col v-if="isLoaded">
      <b-card title="Company details">
        <validation-observer ref="companyValidation">
          <b-form>
            <b-tabs v-model="tabIndex">

              <!-- Tab: Basic info -->
              <b-tab active>
                <template #title>
                  <b-badge
                    v-if="tabErrors.details > 0"
                    class="mr-1"
                    pill
                    variant="danger"
                  >{{ tabErrors.details }}
                  </b-badge>
                  <feather-icon
                    icon="InfoIcon"
                    size="16"
                    class="mr-0 mr-sm-50"
                  />
                  <span class="d-none d-sm-inline">Details</span>
                </template>

                <b-row>
                  <b-col>
                    <b-form-group
                      label="Name"
                      label-for="companyName"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Name"
                        rules="required"
                      >
                        <b-form-input
                          id="companyName"
                          v-model="formData.companyName"
                          name="companyName"
                          :state="errors.length > 0 ? false:null"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col>
                    <b-form-group
                      label="Registry number"
                      label-for="registry-number"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Registry number"
                        rules="required"
                      >
                        <b-form-input
                          id="registry-number"
                          v-model="formData.registryNumber"
                          name="registry-number"
                          :state="errors.length > 0 ? false:null"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <b-form-group
                      label="EORI number"
                      label-for="eoriNumber"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="EORI number"
                        rules="required"
                      >
                        <b-form-input
                          id="eoriNumber"
                          v-model="formData.eoriNumber"
                          name="eoriNumber"
                          :state="errors.length > 0 ? false:null"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="VAT number"
                      label-for="vatNumber"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="VAT number"
                      >
                        <b-form-input
                          id="vatNumber"
                          v-model="formData.vatNumber"
                          name="vatNumber"
                          :state="errors.length > 0 ? false:null"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <b-form-group
                      label="Fiscal reference"
                      label-for="fiscalReference"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Fiscal reference"
                      >
                        <b-form-input
                          id="fiscalReference"
                          v-model="formData.fiscalReference"
                          name="fiscalReference"
                          :state="errors.length > 0 ? false:null"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Bank account number"
                      label-for="bankAccount"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Bank account number"
                      >
                        <b-form-input
                          id="bankAccount"
                          v-model="formData.bankAccount"
                          name="bankAccount"
                          :state="errors.length > 0 ? false:null"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group
                      label="E-mail"
                      label-for="email"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="E-mail"
                        rules="required"
                      >
                        <b-form-input
                          id="email"
                          v-model="formData.email"
                          name="email"
                          :state="errors.length > 0 ? false:null"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Phone"
                      label-for="phone"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Phone"
                      >
                        <b-form-input
                          id="phone"
                          v-model="formData.phone"
                          name="phone"
                          :state="errors.length > 0 ? false:null"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row v-if="areTransportModeSettingsVisible">
                  <b-col>
                    <b-form-group
                      label="Default transport mode"
                      label-for="defaultTransportMode"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Default transport mode"
                      >
                        <b-form-select
                          id="defaultTransportMode"
                          v-model="formData.config.defaultTransportMode"
                          :options="transport_types"
                          :state="errors.length > 0 ? false : null"
                        />
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col>
                    <b-form-group
                      label="Default transport registration number"
                      label-for="defaultTransportRegistrationNumber"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Fiscal reference"
                      >
                        <b-form-input
                          id="defaultTransportRegistrationNumber"
                          v-model="formData.config.defaultTransportRegistrationNumber"
                          name="defaultTransportRegistrationNumber"
                          :state="errors.length > 0 ? false:null"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row class="pb-1">
                  <b-col>
                    <b-form-checkbox
                      v-for="(setting, index) in filteredPublicSettings"
                      :key="index"
                      :id="setting.key"
                      v-model="formData.config[setting.key]"
                      :disabled="computedDisabledStates[setting.key]"
                      :checked="true"
                      switch
                    >
                      {{ setting.label }}
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row
                  v-if="filteredPrivateSettings.length > 0"
                  class="pb-1"
                >
                  <b-col>
                    <h4>Admin settings</h4>
                    <b-form-checkbox
                      v-for="(setting, index) in filteredPrivateSettings"
                      :key="index"
                      :id="setting.key"
                      v-model="formData.config[setting.key]"
                      :disabled="checkFields(setting.check_fields)"
                      :checked="true"
                      switch
                    >
                      {{ setting.label }}
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-tab>

              <!-- Tab: Address -->
              <b-tab>
                <template #title>
                  <b-badge
                    v-if="tabErrors.address > 0"
                    class="mr-1"
                    pill
                    variant="danger"
                  >{{ tabErrors.address }}
                  </b-badge>
                  <feather-icon
                    icon="HomeIcon"
                    size="16"
                    class="mr-0 mr-sm-50"
                  />
                  <span class="d-none d-sm-inline">Address</span>
                </template>
                <!-- Address form -->

                <b-row>
                  <b-col>
                    <b-form-group
                      label="Street and number"
                      label-for="street-number"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Street and number"
                        rules="required"
                      >
                        <b-form-input
                          id="street-number"
                          v-model="formData.streetNumber"
                          name="street-number"
                          :state="errors.length > 0 ? false:null"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <b-form-group
                      label="Postal code"
                      label-for="postalCode"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Postal code"
                        rules="required"
                      >
                        <b-form-input
                          id="postalCode"
                          v-model="formData.postalCode"
                          name="postalCode"
                          :state="errors.length > 0 ? false:null"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col>
                    <b-form-group
                      label="City"
                      label-for="city"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="City"
                        rules="required"
                      >
                        <b-form-input
                          id="city"
                          v-model="formData.city"
                          name="city"
                          :state="errors.length > 0 ? false:null"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <b-form-group
                      label="Region"
                      label-for="region"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Region"
                      >
                        <b-form-input
                          id="region"
                          v-model="formData.region"
                          name="region"
                          :state="errors.length > 0 ? false:null"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Country"
                      label-for="country"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Country"
                        rules="required"
                      >
                        <b-form-input
                          id="country"
                          v-model="formData.country"
                          name="country"
                          :state="errors.length > 0 ? false:null"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-tab>
              <logo
                :company-id="id"
                :logo="formData.logo"
              />
              <company-contract
                :company-id="id"
                :tab-errors="tabErrors"
              />
              <customs-settings
                :company-id="id"
                :country="formData.country"
              />
              <ens-tab
                :company-id="id"
              />
              <webhook-tab
                v-if="$permissions().hasPermission('view company webhook')"
                :company-id="id"
              />
            </b-tabs>
            <!-- Submit button -->
            <b-row v-if="[0, 1].includes(tabIndex)">
              <b-col>
                <b-button
                  type="button"
                  variant="primary"
                  @click="validationForm"
                >
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>
    </b-col>
    <b-col
      v-if="isLoaded === false"
      class="text-center"
    >
      <b-spinner label="Loading..."/>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BButton,
  BTabs,
  BTab,
  BBadge, BSpinner, BFormSelect,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full'
import handleError from '@/views/components/errorHandler'
import CompanyContract from '@/views/company/components/CompanyContract.vue'
import CustomsSettings from '@/views/company/components/CustomsSettings.vue'
import Logo from '@/views/company/components/Logo.vue'
import EnsTab from '@/views/company/components/ens/EnsTab.vue'
import WebhookTab from '@/views/company/components/webhook/WebhookTab.vue'

export default {
  components: {
    BFormSelect,
    WebhookTab,
    BSpinner,
    CompanyContract,
    CustomsSettings,
    Logo,
    EnsTab,
    BRow,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BCard,
    BBadge,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BTabs,
    BTab,
  },
  data() {
    return {
      tabIndex: 0,
      isLoaded: false,
      formData: {
        id: null,
        companyName: '',
        registryNumber: '',
        eoriNumber: '',
        vatNumber: '',
        fiscalReference: '',
        bankAccount: '',
        streetNumber: '',
        postalCode: '',
        city: '',
        region: '',
        country: '',
        email: '',
        phone: '',
        logo: '',
        config: {},
        config_settings: [],
      },
      transport_types: this.$classifiers().getOptions('transport_types'),
      disabledStates: [],
      inputFieldMapping: {
        details: ['Name', 'Registry number', 'EORI number', 'VAT number', 'Fiscal reference', 'E-mail', 'Phone'],
        address: ['Postal code', 'City', 'Country', 'Region', 'Street and number'],
      },
      tabErrors: {
        details: 0,
        address: 0,
      },
    }
  },
  computed: {
    filteredPublicSettings() {
      return (this.formData?.config_settings?.public || []).filter(setting => setting.visible)
    },
    filteredPrivateSettings() {
      return (this.formData?.config_settings?.private || []).filter(setting => setting.visible)
    },
    areTransportModeSettingsVisible() {
      return (this.formData?.config_settings?.public || []).some(setting => {
        if (['defaultTransportMode', 'defaultTransportRegistrationNumber'].includes(setting.key)) {
          return !this.checkFields(setting.check_fields)
        }
        return false
      })
    },
    computedDisabledStates() {
      this.filteredPublicSettings.forEach(setting => {
        this.disabledStates[setting.key] = this.checkFields(setting.check_fields)
        if (this.disabledStates[setting.key]) {
          this.formData.config[setting.key] = false
        }
      })
      this.filteredPrivateSettings.forEach(setting => {
        this.disabledStates[setting.key] = this.checkFields(setting.check_fields)
        if (this.disabledStates[setting.key]) {
          this.formData.config[setting.key] = false
        }
      })

      return this.disabledStates
    },
  },
  created() {
    this.id = this.$attrs.companyId
    this.fetchCompany()
  },
  methods: {
    checkFields(fields) {
      if (!fields || fields.length === 0) {
        return false
      }
      const { config } = this.formData

      return fields.some(field => !config[field])
    },
    fetchCompany() {
      this.isLoaded = false
      this.$http.get(`/v1/companies/${this.id}`)
        .then(response => {
          this.formData.id = response.data.id
          this.formData.companyName = response.data.name
          this.formData.registryNumber = response.data.registry_number
          this.formData.eoriNumber = response.data.eori_number
          this.formData.vatNumber = response.data.vat_number
          this.formData.fiscalReference = response.data.fiscal_reference
          this.formData.streetNumber = response.data.street_and_number
          this.formData.postalCode = response.data.postal_code
          this.formData.city = response.data.city
          this.formData.region = response.data.region
          this.formData.country = response.data.country
          this.formData.email = response.data.email
          this.formData.phone = response.data.phone
          this.formData.logo = response.data.logo
          this.formData.config_settings = response.data.config_settings
          this.formData.bankAccount = response.data.bank_account
          // if there is config and it is not empty array
          if (response.data.config && response.data.config.length !== 0) {
            this.formData.config = response.data.config
          } else {
            this.formData.config = {
              hasMindsoft: false,
              adjustItemsWeight: false,
              autoWeightCalculation: false,
              multiplyItemValueWithQuantity: false,
              gdprDays: null,
              replaceHsCode: false,
              generateH7Invoices: false,
              h1: false,
              translateH7: false,
              translateH1: false,
              defaultTransportMode: '',
              defaultTransportRegistrationNumber: '',
            }
          }
          this.$route.meta.pageTitle = `Edit company (${response.data.name})`
          this.$globalEvent.$emit('update-page-title', this.$route.meta.pageTitle)
          this.isLoaded = true
        })
        .catch(error => {
          handleError(error, this.$toast)
        })
    },
    validationForm() {
      // Handle Tab validations
      this.$data.tabErrors = {
        details: 0,
        address: 0,
      }
      this.$refs.companyValidation.validate().then(() => {
        const { errors } = this.$refs.companyValidation
        Object.keys(errors).forEach(field => {
          if (this.$data.inputFieldMapping.details.includes(field) && errors[field].length > 0) {
            this.$data.tabErrors.details += 1
          }
          if (this.$data.inputFieldMapping.address.includes(field) && errors[field].length > 0) {
            this.$data.tabErrors.address += 1
          }
        })
        if (this.$data.tabErrors.details || this.$data.tabErrors.details) {
          return
        }
        this.editCompany()
      })
    },
    editCompany() {
      this.$http.put(`/v1/companies/${this.id}`, this.$data.formData)
        .then(() => {
          this.$router.push('/companies')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'company edited',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          handleError(error, this.$toast)
        })
    },
  },
}
</script>

<style>

</style>
